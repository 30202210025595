import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import YoutubePlayerLite from "../common/YoutubePlayerLite"

const BlogVideosCategoryListSection = () => {
  const data = useStaticQuery(graphql`
    query BlogVideosCategoryListSectionQuery {
      allPrismicYoutubeVideo(
        limit: 100
        sort: {fields: data___date_published, order: DESC}
      ) {
        edges {
          node {
            data {
              youtube_video_title
              youtube_video_id
              date_published
            }
          }
        }
      }
    }
  `)

  const youtubeVideos = data.allPrismicYoutubeVideo.edges

  return (
    <div className="bg-gray-100 w-full px-4 pt-0 pb-4 md:pb-16">

      <div className="w-full max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12">
          {youtubeVideos.map(item => {
            return (
              <div className="w-full mb-12 md:mb-0">
                <YoutubePlayerLite youtubeID={item.node.data.youtube_video_id} />

                <h2 className="text-gray-900 font-medium text-3xl md:text-4xl mt-6">{item.node.data.youtube_video_title}</h2>
              </div>
            )
          })}  
        </div>
      </div>
    </div>
  )
}

export default BlogVideosCategoryListSection