import * as React from "react"

import Layout from "../../../components/layout/layout"
import BlogHeroSection from "../../../components/sections/blog-hero-section"
import Seo from "../../../components/seo"
import BlogVideosCategoryListSection from "../../../components/sections/blog-videos-category-list-section"

const VideoPage = () => {
  return (
    <Layout>
      <Seo 
        title="Video"
        description="Learn how to create a website with the best templates and themes you'll see in videos."
      />

      <BlogHeroSection />

      <BlogVideosCategoryListSection />
    </Layout>
  )
}

export default VideoPage
